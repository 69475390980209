.login-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  over-flow: hidden;
  display: flex;
  .main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .box {
      padding: 3rem 0 1.5rem;
      margin: 1rem;
      background-color: rgba(255,255,255,0.75);
      border-radius: 1rem;
      border: solid 1px #fff;
      box-shadow: 0 0 5rem rgba(0,0,0,0.12);
    }
  }
}